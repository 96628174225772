import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-prestige-classes"></a><h2>Epic Prestige Classes
    </h2>
    <a id="epic-infiltrator"></a><h3>EPIC INFILTRATOR</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d6.
    <a id="epic-infiltrator-requirements"></a><h6>Requirements</h6>
To qualify to become an epic infiltrator, a character must
fulfill all the following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Alignment:</span> Any
nonchaotic.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
24 ranks, <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> 10 ranks, <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a>
24 ranks, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> 10 ranks. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>.
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Epic Feat:</span> <a href="epicFeats.html#polyglot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Polyglot</a>.
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> Must have
successfully spent one month using the Disguise skill to pose as
someone else. </p>
    <p style={{
      "fontWeight": "bold"
    }}>Class Skills</p>
    <p>The epic infiltrator&#8217;s class skills (and the key ability for
each skill) are <a href="skillsAll.html#appraise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Appraise</a> (Int), <a href="skillsAll.html#balance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Balance</a>
(Dex), <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
(Cha), <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
(Str), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#decipher-script" style={{
        "color": "rgb(87, 158, 182)"
      }}>Decipher Script</a> (Int), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>
(Cha), <a href="skillsAll.html#disable-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disable Device</a> (Int), <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a>
(Cha), <a href="skillsAll.html#escape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape Artist</a> (Dex), <a href="skillsAll.html#forgery" style={{
        "color": "rgb(87, 158, 182)"
      }}>Forgery</a>
(Int), <a href="skillsAll.html#gather-information" style={{
        "color": "rgb(87, 158, 182)"
      }}>Gather Information</a> (Cha), <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
(Dex), <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a> (Cha), <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
(Str), <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> (Wis), <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move
Silently</a> (Dex), <a href="skillsAll.html#open-lock" style={{
        "color": "rgb(87, 158, 182)"
      }}>Open Lock</a> (Dex), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a>
(Wis), <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a> (Wis), <a href="skillsAll.html#sleight-of-hand" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sleight
of Hand</a> (Dex), and <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> (Wis). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
8 + Int modifier. </p>
    <a id="table-the-epic-infiltrator"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Epic Infiltrator 
    </span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>1st
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Improved
cover identity (3), sneak attack +1d6
          </td>
        </tr>
        <tr>
          <td>2nd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Specialist
training</td>
        </tr>
        <tr className="odd-row">
          <td>3rd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Read
thoughts 1/day, <span style={{
              "fontStyle": "italic"
            }}>mind blank</span>
1/day</td>
        </tr>
        <tr>
          <td>4th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Far
senses 1/day, sneak attack +2d6</td>
        </tr>
        <tr className="odd-row">
          <td>5th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Specialist
training, improved cover identity (4)</td>
        </tr>
        <tr>
          <td>6th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Far
senses 2/day</td>
        </tr>
        <tr className="odd-row">
          <td>7th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Read
thoughts 2/day, sneak attack +3d6</td>
        </tr>
        <tr>
          <td>8th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Far
senses 3/day, specialist training</td>
        </tr>
        <tr className="odd-row">
          <td>9th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Improved
cover identity (5)</td>
        </tr>
        <tr>
          <td className="last-row">10th
          </td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Far senses 4/day, sneak attack +4d6
&nbsp;&nbsp;&nbsp; 
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-infiltrator-class-features"></a><h6>Class Features</h6>
The following are class features of the epic infiltrator prestige
class. 
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
An epic infiltrator is proficient with all simple and martial weapons,
all armor, and shields. </p>
    <a id="epic-infiltrator-improved-cover-identity"></a><p><span style={{
        "fontWeight": "bold"
      }}>Improved Cover Identity
(Ex/Su):</span> At 1st level, an epic infiltrator establishes three
specific cover identities, plus one additional cover identity every
four levels thereafter. While operating in a cover identity, the epic
infiltra-tor gains a +4 circumstance bonus on Disguise checks and a +2
circumstance bonus on Bluff and Gather Information checks. When the
epic infiltrator has the option of adding a new cover identity, he or
she may instead work on further perfecting a cover identity already
possessed. An improved cover identity grants a +6 circumstance bonus on
Disguise checks and a +4 circumstance bonus on Bluff and Gather
Information checks while operating in that identity. A specific cover
identity may be improved multiple times, each time adding +2 to the
bonuses. It is impossible to detect the epic infiltrator&#8217;s alignment
with any form of divination. This ability functions exactly like an
undetectable alignment spell, except that it is always active as a
supernatural ability. Only divinations are confounded; spells that
function only against certain alignments affect the epic infiltrator
normally. Should the epic infiltrator wish to &#8220;retire&#8221; a cover identity
and develop a new one, he or she must spend one week practicing the new
identity before he or she earns the bonuses. Cover identities do not in
themselves provide the epic infiltrator with additional skills,
proficiencies, or class features that others might expect of the
pretended professions. The epic infiltrator can switch cover identities
or don a disguise using the Disguise skill in 1d3 minutes. He or she
can also put on or take off armor in one-half the normal time. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Sneak Attack:</span> If an
epic infiltrator can catch an opponent when the opponent is unable to
defend effectively from his or her attack, he or she can strike a vital
spot for extra damage. Any time the epic infiltrator&#8217;s target would be
denied his or her Dexterity bonus to AC (whether he or she actually has
a Dexterity bonus or not), the epic infiltrator&#8217;s attack deals +1d6
points of damage. This extra damage increases by +1d6 points every
three levels. Should the epic infiltrator score a critical hit with a
sneak attack, this extra damage is not multiplied. Ranged attacks can
only count as sneak attacks if the target is 30 feet away or less. With
a sap or an unarmed strike, the epic infiltrator can make a sneak
attack that deals subdual damage instead of normal damage. He or she
cannot use a weapon that deals normal damage to deal subdual damage in
a sneak attack, not even with the usual -4 penalty. An epic infiltrator
can only sneak attack living creatures with discernible
anatomies-undead, constructs, oozes, plants, and incorporeal creatures
lack vital areas to attack. Additionally, any creature immune to
critical hits is similarly immune to sneak attacks. Also, the epic
infiltrator must also be able to see the target well enough to pick out
a vital spot and must be able to reach a vital spot. The epic
infiltrator cannot sneak attack while striking at a creature with
concealment or by striking the limbs of a creature whose vitals are
beyond reach. If an epic infiltrator gets a sneak attack bonus from
another source, the bonuses to damage stack. </p>
    <a id="epic-infiltrator-specialist-training"></a><p><span style={{
        "fontWeight": "bold"
      }}>Specialist Training (Ex):</span>
At 2nd level, the epic infiltrator gains the benefit of focusing his or
her craft on a particular type of work, specializing in one of the
following categories and receiving a +3 bonus on all checks with the
listed skills. Every three levels thereafter, the epic infiltrator
gains specialist training again. He or she may select the same category
more than once. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Concealment:</span> Bluff,
Disguise, and Forgery. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Subterfuge:</span> Hide,
Move Silently, Open Lock, and Pick Pocket. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Espionage:</span> Listen,
Search, and Spot. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Interaction:</span>
Diplomacy, Gather Information, Intimidate, and Sense Motive. </p>
    <a id="epic-infiltrator-read-thoughts"></a><p><span style={{
        "fontWeight": "bold"
      }}>Read Thoughts (Su):</span>
Beginning at 3rd level, the epic infiltrator can detect thoughts as the
spell cast by a 15th-level caster, except that it targets a single
mind. Every four levels thereafter, the epic infiltrator can per-form
this ability one additional time per day. </p>
    <a id="epic-infiltrator-far-senses"></a><p><span style={{
        "fontWeight": "bold"
      }}>Far Senses (Su):</span>
Starting at 4th level, the epic infiltrator can extend his or her
vision or hearing into an area beyond his or her normal range, once per
day, to a distance of 20 feet plus an additional 20 feet per epic
infiltrator level. The epic infiltrator must have personally visited
the physical location earlier to use far senses on it. Barriers do not
impede far senses, and low-light vision or darkvision function normally
if the epic infiltrator has one or both of those abilities. Far senses
can also apply to the epic infiltrator&#8217;s read thoughts ability. Every
two levels beyond 4th the epic infiltrator can perform this ability one
additional time per day. This ability functions as the
clairaudience/clairvoyance spell cast by a 15th-level caster, except
for the limit on range, the need to know the locale beforehand, and the
ability to use the read thoughts ability. </p>
    <p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Mind
Blank (Sp):</span> At 3rd level, the epic infiltrator can become immune
to all mind-affecting spells and divinations once per day, plus one
additional time per day every eight levels thereafter. This ability
works as the mind blank spell cast by a 15th-level caster. </p>
    <p> </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      